<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">あなたが司書課程を履修した際の気持ちについて、もっとも近いものをお選びください。<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="stateQ3"
					label="司書資格取得の意欲"
					:items="motivations"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
				>
					<template v-slot:item="{ item, attrs, on }">
						<v-list-item v-bind="attrs" v-on="on">
							<v-list-item-title class="text-wrap large-height">
								{{ item.text }}
							</v-list-item-title>
						</v-list-item>
					</template>
				</v-select>
			</v-col>
		</v-row>
		<!-- <v-row>
			<v-col>
				<v-chip-group
					v-model="stateQ3"
					active-class="primary--text"
					column
				>
					<v-chip
						v-for="motivation in motivations"
						:key="motivation.value"
						:value="motivation.value"
						filter
					>
						{{ motivation.text }}
					</v-chip>
				</v-chip-group>
			</v-col>
		</v-row> -->
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

@media (max-width: 640px) {
	.large-height {
		height: 74px;
		padding: 10px 6px;
		display: flex;
		align-items: center;
	}
}

.v-chip {
  height: auto;
  white-space: normal;
  min-height: 32px;
  text-align: left;
}

.v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 32px;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurveyQ3',
	data () {
		return {
			form: {
				motivation: null,
			},
			rules: {
				required: value => !!value || '必須'
			},
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			]
		}
	},
	computed: {
		...mapGetters({
			Q3: 'stateQ3',
		}),
		stateQ3: {
			get () {
				return this.Q3
			},
			set (value) {
				this.setQ3(value)
			}
		}
	},
	methods: {
		...mapActions([
			'setQ3'
		]),
	}
}
</script>